<template>
  <div id="search">
    <v-app-bar app color="white">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-progress-linear
        :active="loading"
        :indeterminate="true"
        absolute
        bottom
        color="blue accent-4"
      ></v-progress-linear>

      <v-text-field
        hide-details
        single-line
        ref="search"
        :placeholder="$t('search.placeholder')"
        append-icon="mdi-magnify"
        :value="searchText"
        @input="searchDebounce"
      />
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <!-- Search title -->
        <h3 class="headline mb-1">{{ $t('search.title') }}</h3>

        <v-alert
          type="info"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
          >{{ $t('search.alert') }}</v-alert
        >

        <v-alert
          type="warning"
          border="left"
          class="my-4"
          colored-border
          elevation="2"
          :value="products.length == 0"
          >{{ $t('search.noResults') }}</v-alert
        >

        <easy-serve-product
          v-for="product in products"
          :key="'products' + product.id"
          :product="product"
        ></easy-serve-product>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'debounce';
import EasyServeProduct from './components/EasyServeProduct';

export default {
  components: { EasyServeProduct },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters('restaurant', {
      allProducts: 'products',
    }),

    ...mapGetters('search', {
      searchText: 'text',
    }),

    products() {
      const query = this.getNormalized(this.searchText);

      // What if the query is empty?
      if (query.length == 0) {
        return [];
      }

      return this.allProducts.filter(product => {
        // Search here
        if (this.getNormalized(product.name).indexOf(query) !== -1) {
          return true;
        }

        return this.getNormalized(product.description).indexOf(query) !== -1;
      });
    },
  },

  mounted() {
    // For the refs to work we MUST use the mounted method
    this.$refs.search.focus();
  },

  methods: {
    getNormalized(value) {
      if (!value) return '';

      let normalized = value[this.$i18n.locale]
        ? value[this.$i18n.locale]
        : value[this.$i18n.fallbackLocale]
        ? value[this.$i18n.fallbackLocale]
        : value;

      // If the fallback is not set
      if (typeof normalized != 'string') return '';

      return normalized
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },

    searchDebounce: debounce(function (value) {
      if (value.length > 2) {
        // At least 3 characters
        this.$store.dispatch('search/set', value);
      }
    }, 200),
  },
};
</script>

<style></style>
